<template>
  <router-view></router-view>
  <Loading :is-show="isLoading" :tips="loadingPromptText" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { NavigationGuard } from 'vue-router'

import Loading from '@/components/loading/index.vue'
import { ModuleNames } from '@/constants/vuex'
import { useStore } from '@/store'
import { UserActionEnum } from '@/store/enums/actions/user'
import { WorkspaceActionEnum } from '@/store/enums/actions/workspace'
import { AllocationStateEnum } from '@/store/enums/states/allocation'
import { NetworkStateEnum } from '@/store/enums/states/network'
import { SurveyStateEnum } from '@/store/enums/states/survey'
import { UserStateEnum } from '@/store/enums/states/user'
import { WorkspaceStateEnum } from '@/store/enums/states/workspace'
import { vuexActions } from '@/store/util'

const loadPage: NavigationGuard = async (to, from, next) => {
  const store = useStore()
  const { workspaceId } = to.params

  // Shortcut
  const { surveyKey } = to.query
  if (surveyKey && typeof surveyKey === 'string') {
    next()
    return
  }

  await store.dispatch(vuexActions(ModuleNames.USER, UserActionEnum.GET_USERS), {
    getAll: true
  })

  if (workspaceId && typeof workspaceId === 'string') {
    await store.dispatch(
      vuexActions(ModuleNames.WORKSPACE, WorkspaceActionEnum.GET_WORKSPACE),
      workspaceId
    )
  }
  next()
}

export default defineComponent({
  components: {
    Loading
  },
  beforeRouteEnter: loadPage as NavigationGuard,
  setup() {
    const store = useStore()

    // Loading - all children route loading status will be included here
    const usGettingUser = computed(() => store.state[ModuleNames.USER][UserStateEnum.IS_GETTING])
    const userLoadingPromptText = computed(
      () => store.state[ModuleNames.USER][UserStateEnum.LOADING_PROMPT_TEXT]
    )
    // Workspace
    const isGettingWorkspace = computed(
      () => store.state[ModuleNames.WORKSPACE][WorkspaceStateEnum.IS_GETTING]
    )
    const isCreatingWorkspace = computed(
      () => store.state[ModuleNames.WORKSPACE][WorkspaceStateEnum.IS_CREATING]
    )
    const isUpdatingWorkspace = computed(
      () => store.state[ModuleNames.WORKSPACE][WorkspaceStateEnum.IS_UPDATING]
    )
    const isDeletingWorkspace = computed(
      () => store.state[ModuleNames.WORKSPACE][WorkspaceStateEnum.IS_DELETING]
    )
    const workspaceLoadingPromptText = computed(
      () => store.state[ModuleNames.WORKSPACE][WorkspaceStateEnum.LOADING_PROMPT_TEXT]
    )
    // Survey
    const isGettingSurveys = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.IS_GETTING]
    )
    const isCreatingSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.IS_CREATING]
    )
    const isDeletingSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.IS_DELETING]
    )
    const isExportingSurvey = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.IS_EXPORTING]
    )
    const surveyLoadingPromptText = computed(
      () => store.state[ModuleNames.SURVEY][SurveyStateEnum.LOADING_PROMPT_TEXT]
    )
    // Network
    const isCreatingNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.IS_CREATING]
    )
    const isImportingNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.IS_IMPORTING]
    )
    const isGettingNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.IS_GETTING]
    )
    const isUpdatingNetwork = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.IS_UPDATING]
    )
    const networkLoadingPromptText = computed(
      () => store.state[ModuleNames.NETWORK][NetworkStateEnum.LOADING_PROMPT_TEXT]
    )
    // Allocation
    const isGettingAllocation = computed(
      () => store.state[ModuleNames.ALLOCATION][AllocationStateEnum.IS_GETTING]
    )
    const allocationLoadingPromptText = computed(
      () => store.state[ModuleNames.ALLOCATION][AllocationStateEnum.LOADING_PROMPT_TEXT]
    )

    const isLoading = computed(
      () =>
        usGettingUser.value ||
        isCreatingWorkspace.value ||
        isUpdatingWorkspace.value ||
        isDeletingWorkspace.value ||
        isGettingWorkspace.value ||
        isGettingNetwork.value ||
        isUpdatingNetwork.value ||
        isCreatingNetwork.value ||
        isGettingSurveys.value ||
        isCreatingSurvey.value ||
        isDeletingSurvey.value ||
        isExportingSurvey.value ||
        isGettingAllocation.value ||
        isImportingNetwork.value
    )
    const loadingPromptText = computed(
      () =>
        userLoadingPromptText.value ||
        networkLoadingPromptText.value ||
        surveyLoadingPromptText.value ||
        allocationLoadingPromptText.value ||
        workspaceLoadingPromptText.value
    )

    return {
      loadingPromptText,
      isLoading
    }
  }
})
</script>
